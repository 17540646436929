import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import HomeView from "../views/HomeView.vue";
import FmCloud from "../views/FmCloud/index.vue";
// import NProgress from "nprogress";
import Login from "@/views/Login/index.vue";
import fastlogin from "@/views/Login/fastlogin.vue";

import BtnTest from "@/views/BtnTest/index.vue";
import Account from "@/views/Account/index.vue";
import HomePage from "@/views/HomePage/index.vue";
import Jmdyh from "@/views/HomePage/index3.vue";
import ShareDetail from "@/views/FmCloud/ShareDetail/index.vue";
import Share from "@/views/FmCloud/Share/index.vue";
import Recycle from "@/views/FmCloud/Recycle/index.vue";
import News from "@/views/News/index.vue";
import App from "@/views/App/index.vue";
import Collection from "@/views/Collection/index.vue";
import DownPageA from "@/views/DownPage/pagea.vue";
import DownPageB from "@/views/DownPage/pageb.vue";
import DownPageC from "@/views/DownPage/pagec.vue";
import Help from "@/views/Help/index.vue";
import Askdetail from "@/views/Help/Askdetail/index.vue";
import UserLogout from "@/views/Help/UserLogout/index.vue";
import ChangePhone from "@/views/Help/ChangePhone/index.vue";
import SubmitQuestions from "@/views/Help/SubmitQuestions/index.vue";
import FeedbackHistory from "@/views/Help/FeedbackHistory/index.vue";
import Activities from "@/views/Member/Activities/index.vue";

import redirect from "@/views/redirect/index.vue";
import Welfare from "@/views/Member/Welfare/index.vue";
import DownloadHistory from "@/views/FmCloud/DownloadHistory/index.vue";

import Vip from "@/views/Member/Vip/index.vue";
import utils from "../utils/index";
import conf from "../utils/config/index";

import "nprogress/nprogress.css";
import {getUrl2} from "@/utils/apiList/login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:lang(en-US|zh-HK)?/",
    name: "HomePage",
    component: HomePage,
    meta: {
      requireLogin: false,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
  },
  {
    path: "/:lang(en-US|zh-HK)?/jmdyh",
    name: "jmdyh",
    component: Jmdyh,
    meta: {
      requireLogin: false,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载"
    }
  },
  {
    path: "/:lang(en-US|zh-HK)?/Jmdyh",
    name: "Jmdyh",
    component: Jmdyh,
    meta: {
      requireLogin: false,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载"
    }
  },
  {
    path: "/:lang(en-US|zh-HK)?/",
    name: "HomeView",
    component: HomeView,
    meta: {
      requireLogin: false,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
    children: [
      {
        path: "/:lang(en-US|zh-HK)?/home",
        name: "home",
        redirect: "/drive",
        meta: {
          requireLogin: false,
          title: "飞猫盘｜文件加速传输工具｜云盘｜橘猫旗下新概念云平台",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/s/:id",
        name: "s",
        component: DownPageA,
        meta: {
          requireLogin: false,
          title: "飞猫盘｜文件加速传输工具｜云盘｜橘猫旗下新概念云平台",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/"+store.state.fmPath,
        name: store.state.fmPath,
        component: FmCloud,
        meta: {
          requireLogin: true,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/history",
        name: "history",
        component: DownloadHistory,
        meta: {
          requireLogin: true,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/account",
        name: "account",
        component: Account,
        meta: {
          requireLogin: true,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/share",
        name: "share",
        component: Share,
        meta: {
          requireLogin: true,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/recycle",
        name: "recycle",
        component: Recycle,
        meta: {
          requireLogin: true,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      // {
      //   path: "/news",
      //   name: "news",
      //   component: News,
      //   meta: {
      //     requireLogin: true,
      //     title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
      //   },
      // },
      {
        path: "/:lang(en-US|zh-HK)?/app",
        name: "app",
        component: App,
        meta: {
          requireLogin: false,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      // {
      //   path: "/myCollection",
      //   name: "myCollection",
      //   component: Collection,
      //   meta: {
      //     requireLogin: true,
      //     title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
      //   },
      // },
      {
        path: "/:lang(en-US|zh-HK)?/vip",
        name: "vip",
        component: Vip,
        meta: {
          requireLogin: false,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/redirect",
        name: "redirect",
        component: redirect,
        meta: {
          requireLogin: false,
          title: "飞猫盘｜文件加速传输工具｜云盘｜橘猫旗下新概念云平台"
        }
      },
      // 福利活动
      {
        path: "/:lang(en-US|zh-HK)?/activities",
        name: "activities",
        component: Activities,
        meta: {
          requireLogin: true,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      //福利社
      {
        path: "/:lang(en-US|zh-HK)?/welfare",
        name: "welfare",
        component: Welfare,
        meta: {
          requireLogin: true,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      // 下载A页
      // {
      //   path: "/:lang(en-US|zh-HK)?/s/:id",
      //   name: "s",
      //   component: DownPageA,
      //   meta: {
      //     requireLogin: false,
      //     title: "飞猫盘｜文件加速传输工具｜云盘｜橘猫旗下新概念云平台",
      //   },
      // },
      {
        path: "/:lang(en-US|zh-HK)?/fmDown/:code",
        name: "fmDown",
        component: DownPageB,
        meta: {
          requireLogin: false,
          title: "飞猫盘｜文件加速传输工具｜云盘｜橘猫旗下新概念云平台",
        },
      },
      // 下载过渡页C
      {
        path: "/:lang(en-US|zh-HK)?/tNewdown/:code",
        name: "tNewdown",
        component: DownPageC,
        meta: {
          requireLogin: false,
          title: "飞猫盘｜文件加速传输工具｜云盘｜橘猫旗下新概念云平台",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/help",
        name: "help",
        component: Help,
        meta: {
          requireLogin: true,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
      {
        path: "/:lang(en-US|zh-HK)?/askdetail/:id",
        name: "askdetail",
        component: Askdetail,
        meta: {
          requireLogin: false,
          title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
        },
      },
    ],
  },
  
  {
    path: "/:lang(en-US|zh-HK)?/g/:code",
    name: "g",
    component: ShareDetail,
    meta: {
      requireLogin: false,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
  },
  {
    path: "/:lang(en-US|zh-HK)?/userlogout",
    name: "userlogout",
    component: UserLogout,
    meta: {
      requireLogin: true,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
  },
  {
    path: "/:lang(en-US|zh-HK)?/changephone",
    name: "changephone",
    component: ChangePhone,
    meta: {
      requireLogin: false,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
  },
  {
    path: "/:lang(en-US|zh-HK)?/submitQuestions",
    name: "SubmitQuestions",
    component: SubmitQuestions,
    meta: {
      requireLogin: true,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
  },
  {
    path: "/:lang(en-US|zh-HK)?/feedbackHistory",
    name: "feedbackHistory",
    component: FeedbackHistory,
    meta: {
      requireLogin: true,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
  },
  {
    path: "/:lang(en-US|zh-HK)?/login",
    name: "login",
    component: Login,
    meta: {
      requireLogin: false,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
  },
  {
    path: "/:lang(en-US|zh-HK)?/fastlogin",
    name: "fastlogin",
    component: fastlogin,
    redirect: "/login",
    meta: {
      requireLogin: false,
      title: "飞猫盘 | 文件加速传输工具 | 云盘 | 海量分享 极速下载",
    },
  },
  {
    path: "*", // 此处需特别注意至于最底部
    redirect: "/drive"
  }
];
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
//解决vue路由重复导航错误
//获取原型对象上的push函数

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// 在全局导航守卫中检查元字段-全局前置守卫
router.beforeEach((to, from, next) => {
  // to：route即将进入的路由
  // from：route即将离开的路由
  // NProgress.start();

  if(to.query.platfro){
    localStorage.setItem("platfro", to.query.platfro);
  }
  
  if(!utils.getCookie(conf.fmCookieName) && to.query.platfro){
    localStorage.setItem("platfro", to.query.platfro);
    getUrl2('/user-service/sso/login', { token: to.query.platfro }).then((res) => {
      if (res.status == 1) {
        if(utils.getCookie(conf.fmCookieName)){
          next(to.path);
        } else {
          setTimeout(()=>{
            next(to.path);
          },600)
        }
      }
     });
    return
  } else if(to.query.platfro) {
    next(to.path);
  }


  if(to.name == 'fastlogin' || to.name == 'login'){
    const from = 'overseas';
    const lang = {
      cn: '',
      en: '/en-US',
      hk: '/zh-HK'
    }
    const fmUrl = conf.fmdomestic + lang[store.state.language];
    const fmoverseas = conf.fmoverseas + lang[store.state.language];  
    const fromUrl = fmoverseas + `/${to.query.path || to.name}`;
    const encodedFrom = encodeURIComponent(from);
    const encodedFromUrl = encodeURIComponent(fromUrl);
    if(to.query.tab){
      window.location.href = fmUrl + `/login?path=drive&tab=2&from=${encodedFrom}&fromUrl=${encodedFromUrl}`;
    } else {
      window.location.href = fmUrl + `/login?from=${encodedFrom}&fromUrl=${encodedFromUrl}`;
    }
    return;
  }



  if(to.name != 'vip'){
    store.commit("setAttr", {
      name: "route_ids",
      val: ['A1'],
    })
    store.commit("setAttr", {
      name: "voucher",
      val: '',
    })
  }

  if(!utils.getCookie(Vue.prototype.$glb.fmCookieName)&&store.state.showUpDownBox == 1){
    store.commit("clearUserData");
  }
  
  var ua = navigator.userAgent;
  var ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
  var isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
  var isAndroid = ua.match(/(Android)\s+([\d.]+)/);

  // 兼容老地址匹配 start
  // var ifor = false
  var testreg = new RegExp("[^:]//");
  var durl = window.location.href;
  if (testreg.test(durl)) {
    var url1re = new RegExp("://");
    var url2re = new RegExp("//", "g");
    var url3re = new RegExp(":fmregfm");
    var turl2 = window.location.href.replace(url1re, ":fmregfm");
    turl2 = turl2.replace(url2re, "/");
    durl = turl2.replace(url3re, "://");
  }

  if (durl.indexOf("/#/") !== -1) {
    window.location.href = durl.replace("/#/", "/");
    return;
  }
  var reg = new RegExp("^(.*)/file-([0-9]+).html$");
  var urlres = reg.exec(durl);
  if (urlres !== null && urlres.length === 3) {
    window.location.href = urlres[1] + "/s/" + urlres[2];
    return;
  }
  // 兼容老地址匹配 end
  if (isIphone || isAndroid) {
    if (to.name === "s") {
      next();
    } else if (to.name === "g"){
      next();
    } else if (to.name === "askdetail") {
      window.location.href = conf.shareUrl + '/agreement?code=' + to.params.id
      return;
    } else {
      const fullUrl = window.location.href;
      if (fullUrl.includes('/en-US')) {
        window.location.replace("/wap.html?lang=en-US");
        return
      } if (fullUrl.includes('/zh-HK')) {
        window.location.replace("/wap.html?lang=zh-HK");
        return
      } else {
        window.location.replace("/wap.html");
        return
      }
    }
  }

  if (!utils.getCookie(Vue.prototype.$glb.fmCookieName)&& to.name.indexOf('login') < 0) {
    if (to.meta.requireLogin) {
      if (to.path == '/jmdyh.html' || to.path == '/Jmdyh.html') {
        next("/login?path=drive");
      } else {
        next({
          path: "/login",
          query: {
            path: to.name,
          },
        });
      }
    } else {
      next();
    }
  }
  if (store.state.language == 'en'&&!to.fullPath.includes('/en-US')) {
    next({ path: '/en-US' + to.fullPath })
  } else if (store.state.language == 'hk'&&!to.fullPath.includes('/zh-HK')) {
    next({ path: '/zh-HK' + to.fullPath })
  } else if (store.state.language == 'cn'){
    if (!to.fullPath.includes('/en-US')&&!to.fullPath.includes('/zh-HK')) {
      next();
    } else {
      const newPath = to.fullPath.replace('/en-US', '').replace('/zh-HK', '');
      next({ path: newPath });
    }
  } else {
    next();
  }
});
// 全局后置守卫
router.afterEach(() => {
  // NProgress.done();
});

export default router;
