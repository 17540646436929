<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="VideoDown"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header" style="margin-bottom: 8px">
        <div class="left">APP扫码看视频下载</div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>

      <div class="cen-box">
        <div class="qr-box">
          <div id="qrcode4"></div>
          <div class="Scan-box" v-if="ScanType != 0">
            <svg-btn
              class="icon"
              :icon-class="ScanType == 1 ? 'icon-Scan-ok' : 'icon-Scan-Refresh'"
              :style="{ cursor: ScanType == 1 ? '' : 'pointer' }"
              @click="ScanType == 1 ? '' : refreshFn()"
            ></svg-btn>
            <div class="text">
              {{ ScanType == 1 ? "扫码成功" : "二维码已失效" }}
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px" class="text-box" v-if="ScanType == 0">
          <div>打开飞猫盘APP扫码看视频下载</div>
          <div>看视频过程中请勿刷新网页</div>
        </div>
        <div style="margin-bottom: 38px" v-if="ScanType != 0" class="text-box">
          {{ ScanType == 1 ? "看视频过程中请勿刷新网页" : "请点击刷新二维码" }}
        </div>
        <div class="ed-text">
          没有安装飞猫盘APP？ <span @click="toPath()">点此安装</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
import { eventStatAPI } from "@/utils/apiList/downpage";
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    showVideoDown2: {
      type: Boolean,
      default: false,
    },
    videoQrdata: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      heartbeatTimer: null,
      setTimeoutTimer: null,
      heartbeatT2: false,
      ws: null,
      ScanType: 0,
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", this.handlevideo);
  },
  watch: {
    isShow(val) {
      if (val) {
        this.ScanType = 0;
        this.eventStatAPIFn();
        this.$nextTick(() => {
          if (!this.heartbeatT2) {
            this.connectWebSocket();
          }

          this.createCode(this.videoQrdata.video_ad_qrcode);
          this.setTimeoutTimer = setTimeout(() => {
            if (this.ScanType == 0) {
              this.ScanType = 2;
              this.ws.close();
            }
          }, this.videoQrdata.video_ad_qrcode_ttl * 1000);
        });
      }
    },
    showVideoDown2(val) {
      if (val) {
        this.connectWebSocket();
        this.createCode(this.videoQrdata.video_ad_qrcode);
        this.ScanType = 0;

        this.setTimeoutTimer = setTimeout(() => {
          if (this.ScanType == 0) {
            this.ScanType = 2;
            this.ws.close();
          }
        }, this.videoQrdata.video_ad_qrcode_ttl * 1000);
      }
    },
  },
  beforeDestroy() {
    this.ws.close();
    clearTimeout(this.setTimeoutTimer);
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.handlevideo);
  },
  methods: {
    handlevideo(e) {
      switch (e.target.visibilityState) {
        case "visible":
          if (!this.heartbeatT2 && this.ScanType == 0 && this.isShow) {
            this.connectWebSocket();
            this.setTimeoutTimer = setTimeout(() => {
              if (this.ScanType == 0) {
                this.ScanType = 2;
                this.ws.close();
              }
            }, this.videoQrdata.video_ad_qrcode_ttl * 1000);
          }
          break;
        case "hidden":
          break;
      }
    },
    eventStatAPIFn() {
      eventStatAPI({
        event_id: "P/1-4-10-1014",
      }).then(() => {});
    },
    async refreshFn() {
      await this.$emit("refreshEmit");
    },
    connectWebSocket() {
      // 假设WebSocket服务器地址是ws://localhost:8080

      this.ws = new WebSocket(
        this.$store.state.userInfo.push_url +
          `/ad?push_token=${this.videoQrdata.video_ad_token}` || ""
      );
      this.ws.onopen = () => {
        console.log("ok");
        this.heartbeatTimer = setInterval(() => {
          this.ws.send("hb");
        }, 9000);
        this.heartbeatT2 = true;
      };

      this.ws.onmessage = (message) => {
        let formObj = JSON.parse(message.data);
        if (formObj.msg_id == "0001") {
          // 二维码已经被扫
          this.ScanType = 1;
        } else if (formObj.msg_id == "0002") {
          // 可看视频下载通知，需要调用下载接口
          this.$emit("response2", formObj.data);
          this.$emit("response");
        }
      };

      this.ws.onerror = (error) => {
        console.error("error:");
      };

      this.ws.onclose = () => {
        clearInterval(this.heartbeatTimer);
        this.heartbeatT2 = false;
        console.log("close");
      };
    },
    async clearQRCode() {
      return new Promise((resolve) => {
        document.getElementById("qrcode4").innerHTML = "";
        document.getElementById("qrcode4").textContent = "";
        resolve();
      });
    },
    //生成二维码
    async createCode(code) {
      // 1.创建二维码对象。qrcode(二维码类型（1~40，输入 0 以自动检测）,容错级别（L、M、Q、H）)

      await this.clearQRCode(); // Asynchronous clear
      // createImgTag(cellSize, margin, alt); cellSize 像素宽度,margin补白像素宽度
      this.$nextTick(() => {
        var qr = qrcode(0, "L");
        // 2.添加二维码信息。

        qr.addData(JSON.stringify(code));
        // 3.生成二维码对象（并不显示）。
        qr.make();
        document.getElementById("qrcode4").innerHTML = qr.createImgTag(4, 0);
      });
    },
    toPath() {
      window.open("/app", "_blank");
    },
    async handleClose() {
      await this.clearQRCode();
      clearTimeout(this.setTimeoutTimer);
      this.$emit("handleClose");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.VideoDown {
  .left {
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    @include useTheme {
      color: getVar("text-color2-2c2c2c");
    }
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .cen-box {
    .qr-box {
      width: 120px;
      height: 120px;
      background: #ffffff;
      box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.08);
      border-radius: 7px;
      margin: auto;
      margin-bottom: 16px;
      margin-top: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      @include useTheme {
        border: getVar("border-color-e5e5e5");
      }
      position: relative;

      .Scan-box {
        width: 120px;
        height: 120px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 7px;
        backdrop-filter: blur(4px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        .icon {
          width: 32px;
          height: 32px;
          margin-top: 32px;
        }

        .text {
          height: 20px;
          font-weight: 500;
          font-size: 12px;
          @include useTheme {
            color: getVar("text-color1-2c2c2c");
          }
          line-height: 20px;
        }
      }
    }

    .text-box {
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      @include useTheme {
        color: getVar("text-color1-8a8b92");
      }
    }

    .ed-text {
      text-align: center;
      height: 22px;
      font-weight: 400;
      font-size: 14px;
      @include useTheme {
        color: getVar("text-color1-2c2c2c");
      }
      line-height: 22px;

      span {
        color: #f67942;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
  