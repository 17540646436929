<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="codeDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left"></div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content" v-if="isShow">
        <div class="tab">
          <div class="font" @click="indexSelect = 1" :class="indexSelect == 1?'active':''">代金券</div>
          <div class="font" @click="indexSelect = 2" :class="indexSelect == 2?'active':'font'">优惠码</div>
        </div>
        <div v-if="indexSelect == 1">
          <el-select popper-class="share_select" style="width: 100%;" v-model="couponsId" placeholder="暂无可用代金券" no-data-text="暂无可用代金券">
          <el-option
              v-for="item in couponsList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
              :disabled="item.disabled"
            >
            <div class="share_select_box">
                  <div class="income-name" :class="item.disabled? 'income-name1' : ''">
                    {{ item.title }}
                  </div>
                  <div class="income-right">
                    <div
                      class="income-intr income-daren"
                      style="color: #BDBDBE;font-weight: 400;"
                      v-if="item.disabled"
                    >
                    暂不可用
                    </div>
                    <div class="income-checkbox" v-show="item.id == couponsId">
                      <img
                        class="icon-scheck"
                        :src="$utils.getPng('web4/icon-share-check')"
                      />
                    </div>
                  </div>
                </div>
              </el-option>
          </el-select>
        </div>
        <div v-else>
            <el-input
            v-model="coupeValue"
            placeholder="输入优惠码享受折扣"
          >
          <svg-btn
            v-show="coupeValue != ''"
            @click="coupeValue = ''"
            slot="suffix"
            icon-class="icon-ipt-clear"
          ></svg-btn>
          </el-input>
        </div>
      </div>
      <div class="d-footer">
        <fm-button type="primary" size="small" @click="checkCoupe()"
          >确 定</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    couponsId:{
      type: String || Number,
      default: '',
    },
    couponsList:{
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      coupeValue: "",
      indexSelect:1,
    };
  },
  methods: {
    handleClose() {
      // this.coupeValue = ''
      this.$emit("handleClose");
    },
    checkCoupe() {
      if(this.indexSelect == 1){
        if(this.couponsId === ''){
          //执行关闭弹窗逻辑
          this.$emit("checkCoupon",'empty');
        }else if(this.couponsId === 0){
          this.$emit("checkCoupon",0);
        }else{
          this.$emit("checkCoupon",this.couponsId);
        }
        this.coupeValue = ''
      }else{
        if (this.coupeValue == "") {
          this.$toast("请输入优惠码", 2);
          return;
        }
        this.$emit("checkCoupon",'coupeValue');
        this.$emit("checkCoupe",this.coupeValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.codeDialog {
  .tab {
    margin-top: 36px;
    margin-bottom: 24px;
    height: 36px;
    display: flex;
    // align-items: center;
    @include useTheme {
      box-shadow: getVar("shadow-e5e5e5");
    }
    .font {
      font-size: 16px;
      font-weight: 500;
      line-height: 34px;
      cursor: pointer;
      margin-right: 20px;
    }
    .active {
      color: #f67942;
      border-bottom: 4px solid #f67942;
    }
  }
  .d-footer {
    margin-top: 24px;
  }
  .d-content{
    .svg-icon{
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-right: 8px;
    }
  }
}
</style>
