<template>
  <div>
    <!-- 弹窗式开通会员模块 --> 
    <div
      v-if="isPopup"
      class="isPopup-box"
      :style="`backgroundImage:url(${$utils.getPng('web4/isPopupbg')})`"
    >
      <div class="title-nav">
        <div
          class="user-img"
          :style="`backgroundImage:url(${$utils.getPng('web4/tuoyuan')})`"
        >
          <img
            class="avater avater2"
            :src="$store.state.userInfo.logo || $utils.getPng('user-nopic2')"
          />
        </div>
        <div class="Vip-nav-box">
          <div class="user-name-box">
            <div class="user-name">
              {{ $store.state.userInfo.nickname || "未登录" }}
            </div>
            <img
              style="height: 17px"
              v-if="$store.state.language == 'en'"
              v-show="
                $utils.getCookie($glb.fmCookieName) &&
                memberInfo?.tag?.length == 0
              "
              class="sign-icon"
              src="../../assets/img/free.png"
            />
            <img
              style="height: 17px"
              v-else-if="$store.state.language == 'hk'"
              v-show="
                $utils.getCookie($glb.fmCookieName) &&
                memberInfo?.tag?.length == 0
              "
              class="sign-icon"
              src="../../assets/img/free-hk.png"
            />
            <img
              class="sign-icon"
              style="width: 42px; height: 17px"
              v-else
              v-show="
                $utils.getCookie($glb.fmCookieName) &&
                memberInfo?.tag?.length == 0
              "
              src="../../assets/img/free-1.png"
            />
          </div>
          <div
            class="noVip"
            v-show="
              $utils.getCookie($glb.fmCookieName) &&
              memberInfo?.tag?.length == 0
            "
          >
            {{ $t("vip.openVip") }}
          </div>
        </div>
        <div class="user-vip">
          <div
            class="vip-box"
            v-for="(item, index) in memberInfo?.tag"
            :key="index"
            style="margin-right: 8px; font-size: 0"
          >
            <el-tooltip :content="item.label" placement="top">
              <img
                style="height: 16px"
                :src="
                  item.url.indexOf('icon-dcvip-light') > 0
                    ? $utils.getPng(`web4/icon-dcvip-${$store.state.theme}`)
                    : item.url
                "
                alt=""
              />
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="cen-box">
        <div class="left-box" v-if="memberInfo?.pay_list">
          <div class="left-title">
            {{ memberInfo?.pay_list[vipSelectType].subject_label }}
            <span>{{ memberInfo?.pay_list[vipSelectType].tag_num }}</span>
            {{ $t("vip.Cloudequity") }}
          </div>
          <div
            :style="{
              marginLeft: $store.state.language == 'en' ? '7px' : '24px',
            }"
            class="left-for"
            v-for="(item, index) in memberInfo?.pay_list[vipSelectType].tags"
            :key="index"
          >
            <img
              class="vip-icon"
              :src="
                removePngExtension(item.icon) +
                '_' +
                $store.state.theme +
                '.png'
              "
            />
            <div class="vip-info">
              <div class="vip-title">{{ item.title }}</div>
              <div class="vip-dai">{{ item.lable }}</div>
            </div>
          </div>
          <div class="left-btn" @click="allQuanFn">
            {{ $t("vip.ViewAllBenefits")
            }}<svg-btn
              style="width: 12px; height: 12px; margin-left: 4px"
              icon-class="icon_nextyh"
            />
          </div>
        </div>
        <div class="right-box" v-if="memberInfo?.pay_list">
          <div class="right-top">
            <div
              class="moveLeft"
              @click="scrollTo(0)"
              v-show="scrollButton.left"
            >
              <svg-btn
                style="width: 16px; height: 16px; color: #000"
                icon-class="icon-more-fmp"
              />
            </div>
            <div
              class="moveRight"
              @click="scrollTo(9999)"
              v-show="scrollButton.right"
            >
              <svg-btn
                style="width: 16px; height: 16px; color: #000"
                icon-class="icon-more-fmp"
              />
            </div>
            <div class="vip-type" ref="scrollBox" id="myDiv" @scroll="onScroll">
              <div
                :style="`background-image: ${
                  index == vipSelectType
                    ? `url(${$utils.getPng('web4/vipSelectpop1')})`
                    : `url(${$utils.getPng(
                        $store.state.theme != 'dark'
                          ? 'web4/vipNoSelect'
                          : 'web4/vipNoSelect2'
                      )})`
                }`"
                class="vip-box"
                :class="index == vipSelectType ? 'vip-select' : ''"
                v-for="(item, index) in memberInfo?.pay_list"
                :key="index"
                @click="vipSelect(item,index)"
              >
                <div
                  class="vip-title-top"
                  v-if="upvipinfo?.explain?.info && index == 0"
                >
                  {{ $t("vip.PremiumEscalation") }}
                </div>
                <div class="vip-title">{{ item.subject_label }}</div>
                <!-- <div class="vip-price" v-if="item.pay_id == 1039">
                  <div class="span2">¥</div>
                  {{ item.title_price_label }}
                </div>
                <div class="vip-price" v-else-if="item.pay_id == 1056">
                  <div class="span2">¥</div>
                  {{ item.title_price_label }}
                  <div class="span3">/月</div>
                </div>
                <div class="vip-price" v-else>
                  <div class="span1">低至</div>
                  <div class="span2">¥</div>
                  {{ item.title_price_label }}
                  <div class="span3">
                    {{ item.pay_price_discount == 1 ? "/首月" : "/月" }}
                  </div>
                </div> -->
                <div class="vip-price">
                    <div class="span1">{{item.full_title_label.split('￥')[0]}}</div>
                    <div class="span2">¥</div>
                    {{ getLabel(item, index) }}
                    <div class="span3">
                      {{ item.full_title_label.split(/￥[\d.]*/)[1] }}
                    </div>
                  </div>
                <div
                  class="vip-Discount"
                  style="text-decoration-line: none"
                  v-if="item.pay_id == 1060"
                >
                  {{
                    item.tag3
                  }}
                </div>
                <div
                  class="vip-Discount"
                  style="text-decoration-line: none"
                  v-else-if="item.pay_id == 1057"
                >
                  {{ $t("vip.Renewalfee")
                  }}<span v-if="$store.state.language == 'cn'||$store.state.language == 'hk'">￥</span
                  >{{ item?.sub_price }}
                </div>
                <div
                  class="vip-Discount"
                  v-else-if="
                    item.web_original_price > 0 &&
                    item.pay_id != 1056
                  "
                >
                  ¥{{ item.web_original_price }}
                </div>
                
                <div
                  class="vip-limitText"
                  :class="item.tag1 ? (index ? 'vip-limitType2' : 'vip-limitType1') : ''" >
                  {{item.tag1}}
                </div>
                <!-- <div
                  class="vip-limitText"
                  :class="
                    item.pay_id == 1039
                      ? 'vip-limitType1'
                      : item.pay_id == 1053||item.pay_id == 1059
                      ? 'vip-limitType2'
                      : item.pay_id == 1057 && item.pay_price_discount == 1
                      ? 'vip-limitType2'
                      : ''
                  "
                >
                  {{
                    item.pay_id == 1039
                      ? item.tag1
                      : item.pay_id == 1053||item.pay_id == 1059
                      ? item.tag1
                      : item.pay_id == 1057 && item.pay_price_discount == 1
                      ? item.tag1
                      : ""
                  }}
                </div> -->
              </div>
            </div>
            <div class="vip-text">
              {{ getExtra(memberInfo?.pay_list[vipSelectType]) }}
            </div>
          </div>
          <div class="right-bottom">
            <div class="bottom-top">
              <div class="PaymentTools">
                <div
                  class="ToolsType"
                  :class="index == ToolsSelectType ? 'ToolsSelect' : ''"
                  v-for="(item, index) in payData"
                  :key="index"
                  @click="ToolsSelect(index, item)"
                >
                  <img :src="item.icon" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
            <div class="bottom-bottom">
              <div class="bottom-bottom-left">
                <div class="shifu-box">
                  <div class="span1">{{ $t("vip.ActualPayment") }}：
                    <!-- 补差价升级 -->
                    <div
                      class="span4"
                      v-if="upvipinfo?.explain?.info && vipSelectType == 0"
                      @click="showUpgradeexplain = true"
                    >
                      {{ $t("vip.PremiumEscalation")
                      }}<svg-btn
                        style="width: 12px; height: 12px; margin-left: 2px"
                        icon-class="explain2"
                      />
                    </div>
                    <!-- 补差价升级 -->
                    <!-- 已优惠 -->
                      <div
                      class="span3"
                      v-if="  
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1056' &&
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1057' &&
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1060' &&
                        (memberInfo?.pay_list[vipSelectType]?.coupon == null || coupon_id == 0) &&
                        !isUseCoupon
                      "
                      :style="{paddingRight: $store.state.language == 'en'?'6px':'0'}"
                    >
                      <span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">{{
                        $t("vip.offAlready")
                      }}</span
                      ><span
                        style="margin-right: -2px"
                        v-show="$store.state.language == 'en'"
                        >￥</span
                      ><span>{{
                        !isUseCoupon
                          ? parseInt(
                              memberInfo?.pay_list[vipSelectType]
                                ?.web_original_price
                            ) -
                            parseInt(
                              memberInfo?.pay_list[vipSelectType]?.real_price
                            )
                          : getPrice(
                            memberInfo?.pay_list[vipSelectType]?.real_price
                          )
                      }}</span
                      ><span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">元！</span
                      ><span
                        style="margin-left: 4px"
                        v-show="$store.state.language == 'en'"
                        >{{ $t("vip.offAlready") }}</span
                      >
                      </div>
                      <!-- 已优惠 -->

                       <!-- 立减+优惠 -->
                        <div class="span6" :style="$store.state.language == 'en'? { 'padding-right': '10px' } : {}"   v-if="memberInfo?.pay_list[vipSelectType]?.coupon != null &&  coupon_id != 0">
                          <div class="boxOne">
                            <svg-btn
                            class="icon-quan"
                            icon-class="icon-quan"
                          />
                          {{ $t("vip.Discount") }}￥{{ memberInfo?.pay_list[vipSelectType]?.coupon.remit}}
                          <span class="icon-xian"></span>
                          </div>
                          <div>
                            <span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">{{
                            $t("vip.offAlready")
                          }}</span
                          ><span
                            style="margin-right: -2px"
                            v-show="$store.state.language == 'en'"
                            >￥</span
                          ><span>{{
                            !isUseCoupon
                              ? parseInt(
                                  memberInfo?.pay_list[vipSelectType]
                                    ?.web_original_price
                                ) -
                                parseInt(
                                  memberInfo?.pay_list[vipSelectType]?.real_price
                                ) +
                                parseInt(
                                  memberInfo?.pay_list[vipSelectType]?.coupon.remit
                                )
                              :  getPrice(
                                memberInfo?.pay_list[vipSelectType]?.real_price
                              )
                          }}</span
                          ><span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">元！</span
                          ><span
                            style="margin-left: 4px"
                            v-show="$store.state.language == 'en'"
                            >{{ $t("vip.offAlready") }}</span
                          >
                          </div>
                        </div>
                        <!-- 立减+优惠 -->
                        <!-- 优惠码享受多少折扣 -->
                          <div class="span3" v-if="isUseCoupon && (memberInfo?.pay_list[vipSelectType]?.coupon == null || coupon_id == 0)">
                          已享受{{ parseInt(couponDiscount * 100) / 10 }}折优惠
                          </div>
                        <!-- 优惠码享受多少折扣 -->
                  </div>
                </div>
                <div class="bottom-bottom-left-top">
                  <div class="span2">
                    <span style="margin-right: -5px">￥</span
                    >{{
                      !isUseCoupon
                        ? paymentAmount??paymentAmount
                        : getPrice(
                            memberInfo?.pay_list[vipSelectType]?.real_price
                          )
                    }}
                  </div>
                  <!-- <div class="span3" v-if="isUseCoupon">
                    已享受{{ parseInt(couponDiscount * 100) / 10 }}折优惠
                  </div> -->
                  <!-- <div
                    class="span3"
                    v-if="  
                      memberInfo?.pay_list[vipSelectType]?.pay_id != '1056' &&
                      memberInfo?.pay_list[vipSelectType]?.pay_id != '1057' &&
                      memberInfo?.pay_list[vipSelectType]?.pay_id != '1060' &&
                      !isUseCoupon
                    "
                    :style="{paddingRight: $store.state.language == 'en'?'6px':'0'}"
                  >
                    <span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">{{
                      $t("vip.offAlready")
                    }}</span
                    ><span
                      style="margin-right: -2px"
                      v-show="$store.state.language == 'en'"
                      >￥</span
                    ><span>{{
                      !isUseCoupon
                        ? parseInt(
                            memberInfo?.pay_list[vipSelectType]
                              ?.web_original_price
                          ) -
                          parseInt(
                            memberInfo?.pay_list[vipSelectType]?.real_price
                          )
                        : getPrice(
                            memberInfo?.pay_list[vipSelectType]?.real_price
                          )
                    }}</span
                    ><span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">元！</span
                    ><span
                      style="margin-left: 4px"
                      v-show="$store.state.language == 'en'"
                      >{{ $t("vip.offAlready") }}</span
                    >
                  </div> -->
                  <div
                    class="Renewalfee"
                    v-if="memberInfo?.pay_list[vipSelectType]?.pay_id == 1057"
                  >
                    {{ $t("vip.Renewalfee")
                    }}{{ memberInfo?.pay_list[vipSelectType]?.sub_price
                    }}<span v-if="$store.state.language == 'cn'||$store.state.language == 'hk'">元</span>
                  </div>
                </div>
                <div class="bottom-bottom-left-btn">
                  <fm-button
                    @click="handlerefreshFn(1)"
                    style="width: 264px; height: 52px"
                    >{{ $t("vip.OpenVIP") }}</fm-button
                  >
                </div>
                <div class="bottom-bottom-left-agreement">
                  <div class="agreement">
                    <div class="btn-svg">
                      <svg-btn
                        @click="checkArchives"
                        :icon-class="
                          isChecked
                            ? 'icon-file-check'
                            : 'icon-file-uncheck-' + $store.state.theme
                        "
                      >
                      </svg-btn>
                    </div>
                    <span
                      >{{ $t("agreement.readAndAgree")
                      }}<a
                        :href="`${$glb.fmUrl}/askdetail/89`"
                        target="_blank"
                        >{{
                          vipSelectType == 3
                            ? $t("agreement.memberServiceAgreement2")
                            : $t("agreement.memberServiceAgreement")
                        }}</a
                      >
                      <font v-if="vipSelectType == 3">{{
                        $t("agreement.and")
                      }}</font
                      ><a
                        v-if="vipSelectType == 3"
                        class="sf3"
                        :href="`${$glb.fmUrl}/askdetail/95`"
                        target="_blank"
                        >{{
                          $t("agreement.automaticRenewalServiceAgreement")
                        }}</a
                      >
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="bottom-bottom-right"
                v-if="superSlideData?.length > 0"
              >
                <div
                  class="chakanquanyi"
                  v-show="SelectPid == 'alipay' || SelectPid == 'wxpay'"
                  @click="useyh()"
                >
                  {{ $t("vip.Useode")
                  }}<svg-btn
                    style="width: 12px; height: 12px; margin-left: 4px"
                    icon-class="icon_nextyh"
                  />
                </div>
                <div class="swiper">
                  <div class="slide1"></div>
                  <div class="slide2"></div>
                  <SuperSlide
                    ref="SuperSlide"
                    :superSlideData="superSlideData"
                  ></SuperSlide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 嵌入式会员开通模块 -->
    <div
      v-else
      v-loading="loading"
      element-loading-text="正在开通中，会员服务即将生效"
      class="PayOrderVip-box"
      :style="`backgroundImage:url(${$utils.getPng('web4/PayOrderVipbg')})`"
    >
      <div class="title-nav">
        <div
          class="user-img"
          :style="`backgroundImage:url(${$utils.getPng('web4/tuoyuan')})`"
        >
          <img
            class="avater avater2"
            :src="$store.state.userInfo.logo || $utils.getPng('user-nopic2')"
          />
        </div>
        <div class="Vip-nav-box">
          <div class="user-name-box">
            <div class="user-name">
              {{ $store.state.userInfo.nickname || "未登录" }}
            </div>
            <img
              style="height: 17px"
              v-if="$store.state.language == 'en'"
              v-show="
                $utils.getCookie($glb.fmCookieName) &&
                memberInfo?.tag?.length == 0
              "
              class="sign-icon"
              src="../../assets/img/free.png"
            />
            <img
              style="height: 17px"
              v-else-if="$store.state.language == 'hk'"
              v-show="
                $utils.getCookie($glb.fmCookieName) &&
                memberInfo?.tag?.length == 0
              "
              class="sign-icon"
              src="../../assets/img/free-hk.png"
            />
            <img
              class="sign-icon"
              style="width: 42px; height: 17px"
              v-else
              v-show="
                $utils.getCookie($glb.fmCookieName) &&
                memberInfo?.tag?.length == 0
              "
              src="../../assets/img/free-1.png"
            />
          </div>
          <div
            class="noVip"
            v-show="
              $utils.getCookie($glb.fmCookieName) &&
              memberInfo?.tag?.length == 0
            "
          >
            {{ $t("vip.openVip") }}
          </div>
        </div>
        <div class="user-vip">
          <div
            class="vip-box"
            v-for="(item, index) in memberInfo?.tag"
            :key="index"
            style="margin-right: 8px; font-size: 0"
          >
            <el-tooltip :content="item.label" placement="top">
              <img
                style="height: 16px"
                :src="
                  item.url.indexOf('icon-dcvip-light') > 0
                    ? $utils.getPng(`web4/icon-dcvip-${$store.state.theme}`)
                    : item.url
                "
                alt=""
              />
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="cen-box-box">
        <div class="cen-box">
          <div class="left-box" v-if="memberInfo?.pay_list">
            <div class="left-title">
              {{ memberInfo?.pay_list[vipSelectType].subject_label }}
              <span>{{ memberInfo?.pay_list[vipSelectType].tag_num }}</span>
              {{ $t("vip.Cloudequity") }}
            </div>
            <div
              class="left-for"
              v-for="(item, index) in memberInfo?.pay_list[vipSelectType].tags"
              :key="index"
            >
              <img
                class="vip-icon"
                :src="
                  removePngExtension(item.icon) +
                  '_' +
                  $store.state.theme +
                  '.png'
                "
              />
              <div class="vip-info">
                <div class="vip-title">{{ item.title }}</div>
                <div class="vip-dai">{{ item.lable }}</div>
              </div>
            </div>
            <div class="left-btn" @click="allQuanFn">
              {{ $t("vip.ViewAllBenefits")
              }}<svg-btn
                style="width: 12px; height: 12px; margin-left: 4px"
                icon-class="icon_nextyh"
              />
            </div>
          </div>
          <div class="right-box" v-if="memberInfo?.pay_list">
            <div class="right-top">
              <div class="scroll-box"  style="margin-top: 14px;">
                <div v-show="scrollButton.left" class="scroll-button" @click="scrollTo(0)"><svg-btn icon-class="icon-arrow-up" /></div>
                <div class="vip-type" ref="scrollBox" @scroll="onScroll">
                  <div
                    :style="`background-image: ${
                      index == vipSelectType
                        ? `url(${$utils.getPng('web4/vipSelect')})`
                        : `url(${$utils.getPng(
                            $store.state.theme != 'dark'
                              ? 'web4/vipNoSelect'
                              : 'web4/vipNoSelect2'
                          )})`
                    }`"
                    class="vip-box"
                    :class="index == vipSelectType ? 'vip-select' : ''"
                    v-for="(item, index) in memberInfo?.pay_list"
                    :key="index"
                    ref="vipList"
                    @click="vipSelect(item,index)"
                  >
                    <div
                      class="vip-title-top"
                      v-if="upvipinfo?.explain?.info && index == 0"
                    >
                      {{ $t("vip.PremiumEscalation") }}
                    </div>
                    <div class="vip-title">{{ item.subject_label }}</div>
                    <!-- <div class="vip-price" v-if="item.pay_id == 1039||item.pay_id == 1060">
                      <div class="span2">¥</div>
                      {{ item.title_price_label }}
                    </div>
                    <div class="vip-price" v-else-if="item.pay_id == 1056">
                      <div class="span2">¥</div>
                      {{ item.title_price_label }}
                      <div class="span3">/月</div>
                    </div> -->
                    <div class="vip-price">
                      <div class="span1">{{item.full_title_label.split('￥')[0]}}</div>
                      <div class="span2">¥</div>
                      {{ getLabel(item, index) }}
                      <div class="span3">
                        {{ item.full_title_label.split(/￥[\d.]*/)[1] }}
                      </div>
                    </div>
                    <div
                      class="vip-Discount"
                      style="text-decoration-line: none"
                      v-if="item.pay_id == 1057"
                    >
                      {{ $t("vip.Renewalfee")
                      }}<span v-if="$store.state.language == 'cn'||$store.state.language == 'hk'">￥</span
                      >{{ item?.sub_price }}
                    </div>
                    <div
                      class="vip-Discount"
                      style="text-decoration-line: none"
                      v-else-if="item.pay_id == 1060"
                    >
                      {{
                        item.tag3
                      }}
                    </div>
                    <div
                      class="vip-Discount"
                      v-else-if="
                        item.web_original_price > 0 &&
                        item.pay_id != 1056
                      "
                    >
                      ¥{{ item.web_original_price }}
                    </div>
                    
                    <div
                      class="vip-limitText"
                      :class="item.tag1 ? (index ? 'vip-limitType2' : 'vip-limitType1') : ''" >
                      {{item.tag1}}
                    </div>
                    <!-- <div
                      class="vip-limitText"
                      :class="
                        item.pay_id == 1039
                          ? 'vip-limitType1'
                          : item.pay_id == 1053||item.pay_id == 1059
                          ? 'vip-limitType2'
                          : item.pay_id == 1057 && item.pay_price_discount == 1
                          ? 'vip-limitType2'
                          : ''
                      "
                    >
                      {{
                        item.pay_id == 1039
                          ? item.tag1
                          : item.pay_id == 1053||item.pay_id == 1059
                          ? item.tag1
                          : item.pay_id == 1057 && item.pay_price_discount == 1
                          ? item.tag1
                          : ""
                      }}
                    </div> -->
                  </div>
                </div>
                <div v-show="scrollButton.right" class="scroll-button" style="right: 0;transform: rotate(180deg)" @click="scrollTo(9999)"><svg-btn icon-class="icon-arrow-up" /></div>
              </div>
              <div class="vip-text">
                {{ getExtra(memberInfo?.pay_list[vipSelectType]) }}
              </div>
            </div>
            <div class="right-bottom">
              <div class="bottom-top">
                <div class="PaymentTools">
                  <div
                    class="ToolsType"
                    :class="index == ToolsSelectType ? 'ToolsSelect' : ''"
                    v-for="(item, index) in payData"
                    :key="index"
                    @click="ToolsSelect(index, item)"
                  >
                    <img :src="item.icon" />
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <div
                  class="chakanquanyi"
                  v-show="SelectPid == 'alipay' || SelectPid == 'wxpay'"
                  @click="useyh()"
                >
                  {{ $t("vip.Useode")
                  }}<svg-btn
                    style="width: 12px; height: 12px; margin-left: 4px"
                    icon-class="icon_nextyh"
                  />
                </div>
              </div>
              <div class="bottom-bottom">
                <div class="bottom-bottom-left">
                  <div class="span1">{{ $t("vip.ActualPayment") }}:
                    <!-- 补差价升级 -->
                      <div
                      class="span5"
                      v-if="upvipinfo?.explain?.info && vipSelectType == 0"
                      @click="showUpgradeexplain = true"
                    >
                      {{ $t("vip.PremiumEscalation")
                      }}<svg-btn
                        style="width: 12px; height: 12px; margin-left: 2px"
                        icon-class="explain2"
                      />
                      </div>
                    <!-- 补差价升级 -->

                    <!-- 已优惠 -->
                    <div
                      class="span3"
                      v-if="
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1056' &&
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1057' &&
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1060' &&
                        (memberInfo?.pay_list[vipSelectType]?.coupon == null || coupon_id == 0) &&
                        !isUseCoupon
                      "
                      :style="{paddingRight: $store.state.language == 'en'?'6px':'0'}"
                    >
                      <span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">{{
                        $t("vip.offAlready")
                      }}</span
                      ><span
                        style="margin-right: -2px"
                        v-show="$store.state.language == 'en'"
                        >￥</span
                      ><span>{{
                        !isUseCoupon
                          ? parseInt(
                              memberInfo?.pay_list[vipSelectType]
                                ?.web_original_price
                            ) -
                            parseInt(
                              memberInfo?.pay_list[vipSelectType]?.real_price
                            )
                          : getPrice(
                            memberInfo?.pay_list[vipSelectType]?.real_price
                          )
                      }}</span
                      ><span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">元！</span
                      ><span
                        style="margin-left: 4px"
                        v-show="$store.state.language == 'en'"
                        >{{ $t("vip.offAlready") }}</span
                      >
                    </div>
                    <!-- 已优惠 -->

                    <!-- 立减+优惠 -->
                    <div class="span6" :style="$store.state.language == 'en'? { 'padding-right': '10px' } : {}"  v-if="memberInfo?.pay_list[vipSelectType]?.coupon != null && coupon_id != 0">
                      <div class="boxOne">
                        <svg-btn
                        class="icon-quan"
                        icon-class="icon-quan"
                      />
                      {{ $t("vip.Discount") }}￥{{ memberInfo?.pay_list[vipSelectType]?.coupon.remit}}
                      <span class="icon-xian"></span>
                      </div>
                      <div>
                        <span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">{{
                        $t("vip.offAlready")
                      }}</span
                      ><span
                        style="margin-right: -2px"
                        v-show="$store.state.language == 'en'"
                        >￥</span
                      ><span>{{
                        !isUseCoupon
                          ? parseInt(
                              memberInfo?.pay_list[vipSelectType]
                                ?.web_original_price
                            ) -
                            parseInt(
                              memberInfo?.pay_list[vipSelectType]?.real_price
                            ) +
                              parseInt(
                                memberInfo?.pay_list[vipSelectType]?.coupon.remit
                            )
                          : getPrice(
                            memberInfo?.pay_list[vipSelectType]?.real_price
                          )
                      }}</span
                      ><span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">元！</span
                      ><span
                        style="margin-left: 4px"
                        v-show="$store.state.language == 'en'"
                        >{{ $t("vip.offAlready") }}</span
                      >
                      </div>
                    </div>
                    <!-- 立减+优惠 -->

                    <!-- 优惠码享受多少折扣 -->
                    <div class="span3" v-if="isUseCoupon && (memberInfo?.pay_list[vipSelectType]?.coupon == null || coupon_id == 0)">
                      已享受{{ parseInt(couponDiscount * 100) / 10 }}折优惠
                    </div>
                    <!-- 优惠码享受多少折扣 -->
                  </div>
                  <div class="bottom-bottom-left-top">
                    <div class="span2">
                      <span style="margin-right: -5px">￥</span
                      >{{
                        !isUseCoupon
                          ? paymentAmount??paymentAmount
                          : getPrice(
                            memberInfo?.pay_list[vipSelectType]?.real_price
                          )
                      }}
                    </div>
                    <!-- <div class="span3" v-if="isUseCoupon">
                      已享受{{ parseInt(couponDiscount * 100) / 10 }}折优惠
                    </div> -->
                    <!-- <div
                      class="span3"
                      v-if="
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1056' &&
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1057' &&
                        memberInfo?.pay_list[vipSelectType]?.pay_id != '1060' &&
                        !isUseCoupon
                      "
                      :style="{paddingRight: $store.state.language == 'en'?'6px':'0'}"
                    >
                      <span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">{{
                        $t("vip.offAlready")
                      }}</span
                      ><span
                        style="margin-right: -2px"
                        v-show="$store.state.language == 'en'"
                        >￥</span
                      ><span>{{
                        !isUseCoupon
                          ? parseInt(
                              memberInfo?.pay_list[vipSelectType]
                                ?.web_original_price
                            ) -
                            parseInt(
                              memberInfo?.pay_list[vipSelectType]?.real_price
                            )
                          : getPrice(
                              memberInfo?.pay_list[vipSelectType]?.real_price
                            )
                      }}</span
                      ><span v-show="$store.state.language == 'cn'||$store.state.language == 'hk'">元！</span
                      ><span
                        style="margin-left: 4px"
                        v-show="$store.state.language == 'en'"
                        >{{ $t("vip.offAlready") }}</span
                      >
                    </div> -->
                    <div
                      class="Renewalfee"
                      v-if="memberInfo?.pay_list[vipSelectType]?.pay_id == 1057"
                    >
                      {{ $t("vip.Renewalfee")
                      }}{{ memberInfo?.pay_list[vipSelectType]?.sub_price
                      }}<span v-if="$store.state.language == 'cn'||$store.state.language == 'hk'">元</span>
                    </div>
                    <!-- <div
                      class="span4"
                      v-if="upvipinfo?.explain?.info && vipSelectType == 0"
                      @click="showUpgradeexplain = true"
                    >
                      {{ $t("vip.PremiumEscalation")
                      }}<svg-btn
                        style="width: 12px; height: 12px; margin-left: 2px"
                        icon-class="explain2"
                      />
                    </div> -->
                    <div class="bottom-bottom-left-agreement">
                      <div class="agreement">
                        <div class="btn-svg">
                          <svg-btn
                            @click="checkArchives"
                            :icon-class="
                              isChecked
                                ? 'icon-file-check'
                                : 'icon-file-uncheck-' + $store.state.theme
                            "
                          >
                          </svg-btn>
                        </div>
                        <span
                          >{{ $t("agreement.readAndAgree")
                          }}<a
                            :href="`${$glb.fmUrl}/askdetail/89`"
                            target="_blank"
                            >{{
                              vipSelectType == 3
                                ? $t("agreement.memberServiceAgreement2")
                                : $t("agreement.memberServiceAgreement")
                            }}</a
                          >
                          <font v-if="vipSelectType == 3">{{
                            $t("agreement.and")
                          }}</font
                          ><a
                            v-if="vipSelectType == 3"
                            class="sf3"
                            :href="`${$glb.fmUrl}/askdetail/95`"
                            target="_blank"
                            >{{
                              $t("agreement.automaticRenewalServiceAgreement")
                            }}</a
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-bottom-left-btn">
                    <fm-button
                      @click="handlerefreshFn(1)"
                      style="width: 298px; height: 64px"
                      >{{ $t("vip.OpenVIP") }}</fm-button
                    >
                  </div>
                  <!-- <div class="bottom-bottom-left-agreement">
                    <div class="agreement">
                      <div class="btn-svg">
                        <svg-btn
                          @click="checkArchives"
                          :icon-class="
                            isChecked
                              ? 'icon-file-check'
                              : 'icon-file-uncheck-' + $store.state.theme
                          "
                        >
                        </svg-btn>
                      </div>
                      <span
                        >{{ $t("agreement.readAndAgree")
                        }}<a
                          :href="`${$glb.fmUrl}/askdetail/89`"
                          target="_blank"
                          >{{ $t("agreement.memberServiceAgreement") }}</a
                        >
                        <font v-if="vipSelectType == 3">{{
                          $t("agreement.and")
                        }}</font
                        ><a
                          v-if="vipSelectType == 3"
                          class="sf3"
                          :href="`${$glb.fmUrl}/askdetail/95`"
                          target="_blank"
                          >{{
                            $t("agreement.automaticRenewalServiceAgreement")
                          }}</a
                        >
                      </span>
                    </div>
                  </div> -->
                </div>
                <div
                  class="bottom-bottom-right"
                  v-if="superSlideData?.length > 0"
                >
                  <div class="swiper">
                    <div class="slide1"></div>
                    <div class="slide2"></div>
                    <SuperSlide
                      ref="SuperSlide"
                      :superSlideData="superSlideData"
                    ></SuperSlide>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 优惠码 -->
    <DiscountCodeBox
      ref="DiscountCodeBox"
      :isShow="showDiscountCode"
      :couponsId="coupon_id"
      :couponsList="couponsList"
      @handleClose="showDiscountCode = false"
      @checkCoupon="checkCoupon"
      @checkCoupe="couponValidate"
    ></DiscountCodeBox>
    <!-- 补差价升级说明 -->
    <Upgradeexplain
      ref="Upgradeexplain"
      :isShow="showUpgradeexplain"
      :upvipinfo="upvipinfo"
      @handleClose="showUpgradeexplain = false"
    ></Upgradeexplain>
    <!-- 收银台 -->
    <PayCashier
      ref="PayCashierRef"
      :isShow="showPayCashier"
      :orderInfo="orderInfo"
      @handleClose="showPayCashier = false"
      @handlerefresh="handlerefreshFn"
      @openSuccess="openSuccessFn"
    ></PayCashier>
    <!-- 开通会员成功弹窗 -->
    <SuccessfullyModel
      :payToast="payToast"
      :isShowModel="showSuccessfullyModel"
      @successRefresh="successRefreshFn"
    ></SuccessfullyModel>
  </div>
</template>

<script>
import flowers from "@/utils/animation/flowers";
import lottie from "lottie-web";
import {
  getBuyListAPI,
  payMemberListSimpleAPI,
  couponValidateAPi,
  generatePaySignAPI,
  getSpecStatusAPI,
} from "@/utils/apiList/vip.js";
import SuperSlide from "../Member/SuperSlide.vue";
import Upgradeexplain from "..//Member/Upgradeexplain.vue";
import DiscountCodeBox from "..//Member/DiscountCodeBox.vue";
import PayCashier from "..//Member/PayCashier.vue";
import SuccessfullyModel from "..//Member/SuccessfullyModel.vue";
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SuperSlide,
    Upgradeexplain,
    DiscountCodeBox,
    PayCashier,
    SuccessfullyModel,
  },
  data() {
    return {
      upvipinfo: {},
      memberInfo: {},
      showUpgradeexplain: false, // 补差价升级说明弹窗
      showDiscountCode: false,
      showPayCashier: false,
      showSuccessfullyModel: false,
      vipSelectType: 0,
      ToolsSelectType: 0,
      SelectPid: "alipay",
      superSlideData: [],
      isChecked: true,
      vipList: [],
      scrollButton: {
        left: false,
        right: true
      },
      payData: [
        {
          pid: "alipay",
          name: "支付宝",
          icon: this.$utils.getPng("icon_pay_23"),
        },
        {
          pid: "huabei",
          name: "花呗免息分期",
          icon: this.$utils.getPng("icon_pay_33"),
        },
        {
          pid: "wxpay",
          name: "微信支付",
          icon: this.$utils.getPng("icon_pay_2_11"),
        },
      ],
      route_ids: [],
      voucher: "",
      is_pc: 0,
      payToast: {
        vlist: [],
      },
      animation: "",
      couponDiscount: 1,
      isUseCoupon: false,
      coupndataLast: {},
      myDivleft: 10,
      resultArray: [],
      coupon_id:'',
      couponsList:[],//代金券信息
      cash_id:1,
      idList:[],
      pay_id:'',
      authCode:'',
      authState:'',
      paymentAmount:'',
      isStatus:1,
      loading:false,
    };
  },
  created() {
    this.route_ids = this.$store.state.route_ids.join("-");
    this.voucher = this.$store.state.voucher;
    this.is_pc = this.$store.state.is_pc;
    if (this.voucher == "" && this.route_ids == "A1") {
      this.$utils.routeModeReported("B13");
      this.route_ids = this.$store.state.route_ids.join("-");
    }

    this.getBuyList();
    this.getPcMember();
    if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
      this.$store.commit("clearUserData"); //重置缓存
    }
    if(this.$route.query?.authCode){
      this.loading = true
      this.authCode = this.$route.query?.authCode
      this.authState = this.$route.query?.authState
      this.payTimer = setInterval(() => {
          this.getOrderStatus(this.authCode,this.authState);
      }, 4000);
      this.$router.push({
          path: this.$route.path,
      });
    }
  },
  watch: {
    coupon_id() {
      this.findAmount();
    }
  },
  methods: {
    //获取会员套餐价格
    getLabel(item, loopIndex) {
      let result;
      if (item.coupon) {
        if (loopIndex === this.vipSelectType) {
          if (this.coupon_id === item.coupon.id) {
            result = item.coupon.title_price_label;
          } else {
            result = item.title_price_label;
          }
        } else {
          result = item.coupon.title_price_label;
        }
      } else {
        result = item.title_price_label;
      }
      return result;
    },
    //获取会员套餐描述
    getExtra(item) {
      let result;
      if (item.coupon) {
        if (this.coupon_id === item.coupon.id) {
          result = item.coupon.extra_label;
        } else {
          result = item.extra_label;
        }
      } else {
        result = item.extra_label;
      }
      return result;
    },
    //计算实付金额
    findAmount(){
      const selectedPayInfo = this.memberInfo.pay_list[this.vipSelectType];
      // 不使用优惠券的情况
      if (this.coupon_id == 0) {
        this.paymentAmount = selectedPayInfo? selectedPayInfo.real_price : 0;
      } else {
        if (selectedPayInfo && selectedPayInfo.coupon!== null) {
          let amount = ((Number(selectedPayInfo.real_price) * 100 - Number(selectedPayInfo.coupon.remit) * 100) / 100).toFixed(2);
          let numAmount = Number(amount);
          if (numAmount === Math.floor(numAmount)) {
              this.paymentAmount = numAmount;
          } else {
              this.paymentAmount = amount.replace(/(\.\d*)?0+$/, '$1');
          }
        }
      }
    },
    removePngExtension(url) {
      // 使用正则表达式替换 .png
      return url.replace(/\.png$/, "");
    },
    moveRightFn() {
      var div = document.getElementById("myDiv");
      var left = 0;
      left = -280; //
      this.myDivleft = left;
      div.style.left = left + "px";
    },
    moveLeftFn() {
      var div = document.getElementById("myDiv");
      var left = 0;
      left = 10;
      this.myDivleft = left;
      div.style.left = left + "px";
    },
    allQuanFn() {
      this.$emit("allQuan");
    },
    getPrice(price) {
      return (price * this.couponDiscount).toFixed(2);
    },
    successRefreshFn() {
      this.showSuccessfullyModel = false;
      this.isUseCoupon = false;
      this.$emit("SuccessClose");
      this.animation = "";
      lottie.stop();
      lottie.destroy();
      this.getPcMember();
      this.getBuyList();
    },
    openSuccessFn(item) {
      this.coupon_id = ''
      this.showPayCashier = false;
      this.payToast = item;
      this.showSuccessfullyModel = true;
      this.$nextTick(() => {
        var lottieElement = document.getElementById("lottie");
        if (lottieElement) {
          document.getElementById("lottie").innerHTML = ''
        }
        this.animation = lottie.loadAnimation({
          container: document.getElementById("lottie"), // Required
          animationData: flowers,
          renderer: "svg", // Required
          loop: false, // Optional
          autoplay: true, // Optional
        });
      });
      this.getPcMember();
    },
    handlerefreshFn: _.debounce(
      function (item) {
        if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
          this.goLogin();
          return;
        }

        this.newPay(item, this.coupndataLast);
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    goLogin() {
      let path = this.$route.fullPath.toString().slice(1);
      this.$router.push({ path: "/login", query: { path: path } });
      return;
    },
    newPay(type, coupndata = {}) {
      if (!this.isChecked) {
        this.$toast("请勾选协议后再支付", 4);
        return;
      }

      const switchs =
        this.$store.state.userInfo.switchs.path_official_channel_switch == 0 &&
        this.$store.state.userInfo.switchs.path_daren_channel_switch == 0;
      const requestId = Date.now();
      this.currentRequestId = requestId; //接口返回时间不一致差别较大 获取当前请求时间 一致时再生成二维码（防止月度会员微信支付接口返回需要594ms 连续包月300ms 快速切换时 切换到连续包月后 但是微信支付接口反应慢会覆盖连续包月的二维码）

      if (type == 3) {
        generatePaySignAPI({
          pay_id: this.memberInfo.pay_list[this.vipSelectType].pay_id,
          pay_type: this.payData[this.ToolsSelectType].pid,
          coupon: coupndata.coupon ? coupndata.coupon : "",
          voucher: this.voucher,
          page_num_list: switchs ? "" : this.route_ids,
          coupon_user_id:this.coupon_id != 0?this.coupon_id:'',
          is_pc: this.is_pc,
          scene: "wap",
        })
          .then((res) => {
            if (res.status == 1) {
              const newWindow = window.open("", "_blank");
              newWindow.document.write(res.data.shtml);
            } else if (res.status == 50013) {
              this.$toast(res.msg, 2);
            } else if (res.status == 0) {
              this.$toast(res.msg, 4);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }

      generatePaySignAPI({
        pay_id: this.memberInfo.pay_list[this.vipSelectType].pay_id,
        pay_type: this.payData[this.ToolsSelectType].pid,
        coupon: coupndata.coupon ? coupndata.coupon : "",
        voucher: this.voucher,
        page_num_list: switchs ? "" : this.route_ids,
        coupon_user_id:this.coupon_id != 0?this.coupon_id:'',
        is_pc: this.is_pc,
        route:this.$route.fullPath,
      })
        .then((res) => {
          if (res.status == 1) {
            if(res.data.open_type && res.data.open_type == 1 && this.isStatus == 1){
                window.open(res.data.qrcode,"_blank")
                return
            }
            this.orderInfo = {
              ...res.data,
              pay_id: this.memberInfo.pay_list[this.vipSelectType].pay_id,
              new_real_price: !this.isUseCoupon
                ? this.paymentAmount
                : this.getPrice(
                    this.memberInfo?.pay_list[this.vipSelectType]?.real_price
                  ),
            };
            if(this.payData[this.ToolsSelectType]?.prompt){
              this.orderInfo.prompt = this.payData[this.ToolsSelectType]?.prompt
            }
            if (this.currentRequestId == requestId) {
              if (type == 1) {
                this.showPayCashier = true;
              } else if (type == 2) {
                this.$refs.PayCashierRef.createCode(this.orderInfo);
              }
            }
            if(this.coupon_id != 0 && res.data.coupon_msg != null){
              this.$toast(res.data.coupon_msg, 4);
            }
          } else if (res.status == 50013) {
            this.$toast(res.msg, 2);
          } else if (res.status == 0) {
            this.$toast(res.msg, 4);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrderStatus(authCode,authState){
      let data = {
        auth_code:authCode,
        auth_state:authState,
      };
        getSpecStatusAPI(data)
        .then((res) => {
          if(res.status == 1) {
              this.loading = false
              this.$emit('closeLoading')
              this.openSuccessFn(res.data.pay_toast);
              clearInterval(this.payTimer);
          }
          if(res.status == 2) {
              this.loading = false
              this.$emit('closeLoading')
              clearInterval(this.payTimer);
          }
          if(res.status == 3) {
              this.loading = false
              this.$emit('closeLoading')
              this.$toast(res.msg,2)
              clearInterval(this.payTimer);
          }
          if(res.status == 1012) {
              this.loading = false
              this.$emit('closeLoading')
              clearInterval(this.payTimer);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false
          this.$emit('closeLoading')
        });
    },
    closeLoading(){
      this.$emit('closeLoading')
    },
    couponValidate: _.debounce(
      function (item) {
        if(this.upvipinfo?.explain?.info && this.vipSelectType == 0){
          if(this.upvipinfo.preferential != 1){
            this.isUseCoupon = false;
            return
          }
        }
        couponValidateAPi({
          coupon: item,
        })
          .then((res) => {
            if (res.status == 1) {
              this.$toast(
                `使用当前优惠码购买，享受${res.data.discount_label}折优惠`,
                1
              );
              this.couponDiscount = res.data.discount;
              this.coupndataLast = {
                coupon: this.$refs.DiscountCodeBox.coupeValue,
                discount: res.data.discount,
              }; //将本次带的优惠码存储在coupndataLast,切换套餐时需要保留优惠只有在切换套餐至连续包月和花呗时清空coupndataLast
              if (res.data.discount) {
                this.isUseCoupon = true;
                this.couponDiscount = res.data.discount;
              } else {
                this.isUseCoupon = false;
                this.couponDiscount = 1;
              }
              // this.newPay(1, {
              //   coupon: this.$refs.DiscountCodeBox.coupeValue,
              //   discount: res.data.discount,
              // });
              this.showDiscountCode = false;
            } else {
              this.$toast(res.msg, 2);
              this.$refs.DiscountCodeBox.coupeValue = "";
              this.coupndataLast = {};
              this.showDiscountCode = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    getPcMember() {
      payMemberListSimpleAPI()
        .then((res) => {
          if (res.status == 1) {
            this.resultArray = [];

            res.data.pay_list[this.vipSelectType].payment_channel.forEach(
              (item, index) => {
                if (
                  item.key != "4" &&
                  item.key != "7" &&
                  item.key != "8" &&
                  item.value == "1"
                ) {
                  this.resultArray.push(item); // 添加数字 1
                }
              }
            );
            this.payDataFn();
            this.$store.commit("setAttr", {
              name: "memberBenefitsImg",
              val: res.data.member_benefits_img,
            });
            this.memberInfo = res.data;
            this.$nextTick(() => {
              if(this.$refs.scrollBox) this.scrollButton.right = this.$refs.scrollBox.scrollWidth > this.$refs.scrollBox.clientWidth
            })
            this.memberInfo.pay_list
            this.upvipinfo = res.data.up_vip_info || [];
            this.couponsList = res.data.coupons || []
            //获取默认选中第一个的值
            if(this.memberInfo.pay_list[this.vipSelectType]?.coupon != null){
                this.coupon_id = this.memberInfo.pay_list[this.vipSelectType]?.coupon.id
            }
            this.pay_id = this.memberInfo.pay_list[this.vipSelectType]?.pay_id
            if(this.couponsList.length > 0){
              let arr = []
              this.couponsList.forEach(item =>{
                arr.push(item.vip_ids)
              })
              this.idList = [...new Set(arr)];
              this.checkCash()
            }
            this.findAmount();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择代金券点击确认
    checkCoupon(row){
      this.coupon_id = row
      //执行关闭弹窗逻辑
      if(row == 'empty'){
        this.coupon_id = ''
        this.showDiscountCode = false;
        return
      }
      if(row == 'coupeValue' && this.couponsList.length > 0){
        //如果选择的是优惠码
        //判断下拉框内有没有id为0的选项
        const hasIdZero = this.couponsList.some(item => item.id === 0);
        if (hasIdZero) {
            this.coupon_id = 0
        }else{
            this.coupon_id = ''
        }
      }else{
         //如果选择的是代金券
        this.coupndataLast = {};
        this.isUseCoupon = false;
        this.couponDiscount = 1;
      }
      this.showDiscountCode = false;
    },
    //操作代金券数组
    checkCash(){
      if(this.idList.includes(this.pay_id)){
        this.couponsList.forEach((item) => {
            if (item.id == this.coupon_id || item.vip_ids == this.pay_id || item.id == 0) {
                item.disabled = false;
            } else {
                item.disabled = true;
            }
          });
        //后端说id不会返回0，则不使用赋值id为0
        //先检查是否已经存在id为0的元素
        const hasIdZero = this.couponsList.some(item => item.id === 0);
          if (!hasIdZero) {
              this.couponsList.push({id: 0, title: "不使用", disabled: false});
          }
        //如何选择了能使用的会员套餐且选择的是不使用，则把coupon_id变为0
        if(this.memberInfo.pay_list[this.vipSelectType]?.coupon == null){
          this.coupon_id = 0
        }
      }else{
        this.coupon_id = ''
        this.couponsList = this.couponsList.reduce((acc, item) => {
            if (item.id!= 0) {
                item.disabled = true;
                acc.push(item);
            }
            return acc;
        }, []);
      }
    },
    payDataFn(type) {
      this.payData = [];
      this.resultArray.forEach((item) => {
        if (item.key == 1) {
          this.payData.push({
            pid: "alipay",
            name: "支付宝",
            icon: this.$utils.getPng("icon_pay_23"),
          });
        } else if (item.key == 2) {
          this.payData.push({
            pid: "huabei",
            name: "花呗免息分期",
            icon: this.$utils.getPng("icon_pay_33"),
          });
        } else if (item.key == 3) {
          this.payData.push({
            pid: "wxpay",
            name: "微信支付",
            icon: this.$utils.getPng("icon_pay_2_11"),
          });
        } else if (item.key == 5) {
          this.payData.push({
            name: "连续包月",
            pid: "cycle",
            icon: this.$utils.getPng("icon_pay_23"),
          });
        } else if (item.key == 6) {
          this.payData.push({
            pid: "alipay",
            name: "支付宝",
            icon: this.$utils.getPng("icon_pay_23"),
          });
        }else if(item.key == 9) {
          this.payData.push({
            pid: "alipay",
            name: "支付宝",
            icon: this.$utils.getPng("icon_pay_23"),
            prompt:"请勿重复扫描支付二维码，以免支付失败",
          });
        }
      });

      if (this.payData[0].pid == "cycle") {
        this.SelectPid = "cycle";
      } else if (this.payData[0].pid == "wxpay") {
        this.SelectPid = "wxpay";
      } else if (this.payData[0].pid == "huabei") {
        this.SelectPid = "huabei";
      } else {
        if (this.SelectPid == "huabei" && type == 1) {
          this.SelectPid = "huabei";
        } else {
          this.SelectPid = "alipay";
        }
      }

      this.resultArray.forEach((item) => {
        if (item.select == 1) {
          this.SelectPid = [
            "alipay",
            "alipay",
            "huabei",
            "wxpay",
            "alipay",
            "cycle",
            "alipay",
            "alipay",
            "alipay",
          ][item.key];
        }
      });
    },
    //勾选协议
    checkArchives() {
      this.isChecked = !this.isChecked;
      // if (this.isout && this.isChecked) {
      //   this.refresh();
      // }
    },
    getBuyList() {
      getBuyListAPI()
        .then((res) => {
          if (res.status == 1) {
            let listArr = res.data.list.map((item) => {
              return { ...item, id: Math.random(0, 1) };
            });
            this.superSlideData = listArr;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    useyh() {
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      this.showDiscountCode = true;
    },
    onScroll(e) {
      this.scrollButton.left = e.target.scrollLeft > 0
      this.scrollButton.right = e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth - 20
    },
    scrollTo(val) {
      this.$refs.scrollBox.scrollTo({left: val, behavior: 'smooth'});
    },
    vipSelect(item,index) {
      if(!this.isPopup) {
        const el = this.$refs.scrollBox.children[index]
        if(el.offsetLeft < this.$refs.scrollBox.scrollLeft) {
          this.scrollTo(0)
        }
        else if(el.offsetLeft + el.clientWidth>this.$refs.scrollBox.scrollLeft + this.$refs.scrollBox.clientWidth) {
          this.scrollTo(9999)
        }
      }
      this.vipSelectType = index;
      this.ToolsSelectType = 0;
      this.$refs.DiscountCodeBox.coupeValue = "";
      this.coupndataLast = {};
      this.isUseCoupon = false;
      this.resultArray = [];
      this.pay_id = item.pay_id
      this.isStatus = 1
      if(item.coupon != null){
        this.coupon_id = item.coupon.id
      }else{
         this.coupon_id = ''
      }
      this.memberInfo.pay_list[this.vipSelectType].payment_channel.forEach(
        (item, index) => {
          if (
            item.key != "4" &&
            item.key != "7" &&
            item.key != "8" &&
            item.value == "1"
          ) {
            this.resultArray.push(item); // 添加数字 1
          }
        }
      );
      this.findAmount()
      this.checkCash()
      this.payDataFn();
    },
    vipSelect2(index) {
      if (this.vipSelectType != 0) {
        this.ToolsSelectType = 0;
        this.$refs.DiscountCodeBox.coupeValue = "";
        this.coupndataLast = {};
        this.isUseCoupon = false;
      }
      this.vipSelectType = index;
      this.resultArray = [];
      this.ToolsSelectType = 0;
      this.memberInfo.pay_list[this.vipSelectType].payment_channel.forEach(
        (item, index) => {
          if (
            item.key != "4" &&
            item.key != "7" &&
            item.key != "8" &&
            item.value == "1"
          ) {
            this.resultArray.push(item); // 添加数字 1
          }
        }
      );
      if(this.memberInfo.pay_list[this.vipSelectType]?.coupon != null){
          this.coupon_id = this.memberInfo.pay_list[this.vipSelectType]?.coupon.id
      }else{
        this.coupon_id = ''
      }
      this.getPcMember()
    },
    ToolsSelect(index, item) {
      this.ToolsSelectType = index;
      this.SelectPid = item.pid;
      if (item.pid == "huabei") {
        this.coupndataLast = {};
        this.$refs.DiscountCodeBox.coupeValue = "";
        this.isUseCoupon = false;
        //如如果选中花呗则把coupon_id置为0，清空优惠
        const hasIdZero = this.couponsList.some(item => item.id === 0);
        if (hasIdZero) {
            this.coupon_id = 0
        }else{
            this.coupon_id = ''
        }
      }else{
        //反之则给coupon_id赋值优惠券id
        if(!this.isUseCoupon){
          if(this.memberInfo.pay_list[this.vipSelectType]?.coupon != null){
            this.coupon_id = this.memberInfo.pay_list[this.vipSelectType]?.coupon.id
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
