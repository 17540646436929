<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="codeDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header header2">
        <div class="left">收银台</div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <div class="title">
          <img :src="orderInfo.pay_type == 'wxpay' ? payData[2].icon : orderInfo.pay_type == 'huabei' ? payData[1].icon : payData[0].icon" />
          <sapn>{{orderInfo.pay_type == 'wxpay' ? '微信扫码' : orderInfo.pay_type == 'alipay' || orderInfo.pay_type == 'cycle' ? '支付宝扫码' : '花呗免息分期'}}付款</sapn>
        </div>
        <div class="qr" v-loading="ewmloading">
          <div id="qrcode3"></div>
          <div
            class="mb1 mb2 pointer"
            :style="
              $store.state.theme == 'light'
                ? 'background: rgba(0, 0, 0, 0.8);'
                : 'background: rgba(0, 0, 0, 0.9);'
            "
            v-if="order_status == 2"
            @click="refresh"
          >
            <img class="imgdb" :src="$utils.getPng('iconsx')" alt="" />
            请点击刷新
          </div>
        </div>
        <!-- 只有key为9的情况下才会出现这个提示语请勿重复扫描支付二维码，以免支付失败 -->
        <div class="prompt" v-if="orderInfo?.prompt">
            {{ orderInfo.prompt }}
        </div>
        <div class="jiage" :style="{ 'margin-top': orderInfo.prompt? '12px' : '24px' }">
          <span class="span1">{{ $t("vip.ActualPayment") }}:</span><span class="span2"><span style="margin-right: -5px;">￥</span>{{ orderInfo.new_real_price }}</span>
        </div>
        <div class="zfb" v-if="orderInfo.pay_type == 'alipay' || orderInfo.pay_type == 'huabei'" @click="orderInfo?.url ? formPayUrl() : formPay()">手机不在身边？点此支付宝网页支付</div>
        <!-- 占位 -->
        <div class="zfb" v-else></div>
      </div>
    </div>
  </el-dialog>
</template>
  
<script>
import { getOrderStateAPI } from "@/utils/apiList/vip.js";
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    orderInfo: {
      type: Object,
      default: {},
    },
  },
  watch: {
    isShow(val) {
      if (val) {
        this.$nextTick(() => {
          this.createCode(this.orderInfo);
        });
      }
    },
  },
  data() {
    return {
      order_status: 0,
      ewmloading: false,
      payData: [
        {
          pid: "alipay",
          name: "支付宝",
          icon: this.$utils.getPng("icon_pay_23"),
        },
        {
          pid: "huabei",
          name: "花呗免息分期",
          icon: this.$utils.getPng("icon_pay_33"),
        },
        {
          pid: "wxpay",
          name: "微信支付",
          icon: this.$utils.getPng("icon_pay_2_11"),
        },
      ],
    };
  },
  mounted() {},
  methods: {
    formPayUrl(){
      if(this.orderInfo?.url){
        window.open(this.orderInfo?.url, "_blank")
      }
    },
    formPay(){
      this.$emit("handlerefresh", 3);
    },
    refresh(){
      this.ewmloading = true
      this.$emit("handlerefresh", 2);
    },
    async clearQRCode() {
      return new Promise((resolve) => {
        document.getElementById("qrcode3").innerHTML = "";
        document.getElementById("qrcode3").textContent = "";
        resolve();
      });
    },
    //生成支付二维码
    async createCode(code) {
      
      // 1.创建二维码对象。qrcode(二维码类型（1~40，输入 0 以自动检测）,容错级别（L、M、Q、H）)

      await this.clearQRCode(); // Asynchronous clear
      // createImgTag(cellSize, margin, alt); cellSize 像素宽度,margin补白像素宽度
      this.$nextTick(() => {
        var qr = qrcode(0, "L");
        // 2.添加二维码信息。
        qr.addData(code.qrcode);
        // 3.生成二维码对象（并不显示）。
        qr.make();
        document.getElementById("qrcode3").innerHTML = qr.createImgTag(4, 0);
        this.ewmloading = false;
        clearInterval(this.payTimer);
        this.order_status = 0;
        this.payTimer = setInterval(() => {
          this.checkOrder(code);
        }, 4000);
      });
    },
    checkOrder: _.debounce(
      function (code) {
        //除了 wxpay 全部是 alipay
        let order = {
          order_id: code.out_trade_no,
          pay_type: code.pay_type,
          pay_id: code.pay_id, // 会员id
        };
        getOrderStateAPI(order)
          .then((res) => {
            this.order_status = res.status;
            if (res.status == 2) {
              clearInterval(this.payTimer);
            }
            if (res.status == 1) {
              this.$emit("openSuccess", res.data.pay_toast);
              clearInterval(this.payTimer);
            }
            if (res.status == 1012) {
              clearInterval(this.payTimer);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    handleClose() {
      clearInterval(this.payTimer);
      this.$emit("handleClose");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.codeDialog {
  .left {
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    @include useTheme {
      color: getVar("text-color2-2c2c2c");
    }
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .tab {
    margin-top: 36px;
    margin-bottom: 24px;
    height: 36px;
    @include useTheme {
      box-shadow: getVar("shadow-e5e5e5");
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
    .active {
      color: #f67942;
      border-bottom: 4px solid #f67942;
      padding-bottom: 10px;
    }
  }
  .d-footer {
    margin-top: 24px;
  }
  .d-content {
    width: 480px;
    margin-left: -24px;
    margin-top: 16px;
    // box-shadow: 0px -1px 0px 0px #e5e5e5;
    @include useTheme {
      box-shadow: getVar("news-box-shadow3");
    }

    .title {
      padding-top: 30px;
      margin-bottom: 52px;
      height: 28px;
      font-weight: 500;
      font-size: 20px;
      // color: #2c2c2c;
      @include useTheme {
        color: getVar("text-color1-2c2c2c");
      }
      line-height: 28px;
      text-align: center;
      display: flex;
      justify-content: center;
      img {
        width: 28px;
        height: 28px;
        margin-right: 12px;
        object-fit: cover;
      }
    }
    .qr {
      width: 188px;
      height: 188px;
      margin: auto;
      box-shadow: 0px 6px 42px 2px rgba(0, 0, 0, 0.1);
      border-radius: 11px;
      // border: 1px solid #e5e5e5;
      @include useTheme {
        border: getVar('border-color-e5e5e5');
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;

      .mb1 {
        border-radius: 7px;
        background: rgba(0, 0, 0, 0.8);
        font-size: 13px;
        font-weight: 400;
        color: #fff;
        position: absolute;
        width: 188px;
        height: 188px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .mb2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 28px;
          height: 28px;
          margin-bottom: 6px;
        }
      }
    }
    .jiage {
      margin-top: 24px;
      text-align: center;
      .span1 {
        height: 18px;
        font-weight: 400;
        font-size: 13px;
        // color: #8a8b92;
        @include useTheme {
          color: getVar("text-color1-8a8b92");
        }
        line-height: 18px;
      }
      .span2 {
        height: 50px;
        font-weight: 600;
        font-size: 36px;
        color: #f67942;
        line-height: 50px;
      }
    }
    .prompt{
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      color: #8A8B92;
      line-height: 20px;
      margin-top: 8px;
    }
    .zfb {
      margin-top: 8px;
      text-align: center;
      height: 20px;
      font-weight: 400;
      font-size: 12px;
      color: #1777ff;
      line-height: 20px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
</style>
  