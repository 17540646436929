import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data){
  if (data?.ak){
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  }else{
    return data
  }
}

function inParameter(data){
  if (data){
    return { sn: ed, jt: utils.secret(data, ak)}
  }else{
    return data
  }
}

//判断分享的文件是否需要验证取件码
export function checkFilePucode(data) {
    return request({
        url: "/disk-service/down/checkFilePucode",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

export function appDownUrl() {
    return request({
        url: "/user-service/common/appDownUrl",
        method: "post",
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

// h5获取分享文件信息
export function h5FileShareDetail(data) {
    return request({
        url: "/disk-service/file/h5FileShareDetail",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

// h5获取分享合集信息
export function h5GatherDetail(data) {
    return request({
        url: "/disk-service/gather/h5GatherDetail",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

//获取下载页文件信息
export function getDownFileInfo(data) {
    return request({
        url: "/disk-service/down/detail",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

// 下载页vip下载 
export function svipDown(data) {
    return request({
        url: "/disk-service/down/svipDown",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

// 获取文件加密下载地址
export function getNewSecurl(data) {
  return request({
      url: "/disk-service/down/getNewSecurl",
      method: "post",
      data: inParameter(data),
  }).then((res)=>{
      res.data = decrypt(res.data)
      return res
  }).catch((err) => {
      console.log(err);
  });
}

// 文件转存
export function savefile(data) {
    return request({
        url: "/disk-service/down/savefile",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

//获取福利点下载额度 
export function checkPopupInfo(data) {
    return request({
        url: "/disk-service/down/checkPopupInfo",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

//普通下载 
export function downByPoint(data) {
    return request({
        url: "/disk-service/down/downByPoint",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
//Electronpop.vue组件 唤起客户端轮询状态
export function getWebarouseAPI(data) {
    return request({
        url: "/disk-service/common/getWebarouse",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

// /common/webarouse
export function webarouseAPI(data) {
    return request({
        url: "/disk-service/common/webarouse",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

//接口触发三元一天弹窗给后端记录次数 只展现三次
export function activePopupStatAPI(data) {
    return request({
        url: "/disk-service/stat/activePopupStat",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

//二合一转存
export function turnSaveFile(data) {
    return request({
        url: "/disk-service/down/turnSaveFile",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {});
}

//获取文件模式
export function getFileRebateModelAPI(data) {
    return request({
        url: "/disk-service/down/getFileRebateModel",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {});
}

// 扫码下载弹窗曝光埋点接口
export function eventStatAPI(data) {
    return request({
        url: "/disk-service/stat/eventStat",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {});
}

// 获取看视频下载二维码信息
export function getAdDownloadQrcodeAPI(data) {
    return request({
        url: "/disk-service/file/getAdDownloadQrcode",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {});
}

// 获取代金券信息
export function getCashList(data) {
  return request({
      url: "/user-service/popup/getVipCouponPopupList",
      method: "post",
      data: inParameter(data),
  }).then((res)=>{
      res.data = decrypt(res.data)
      return res
  }).catch((err) => {});
}
// 点击收下代金券
export function checkCashList(data) {
  return request({
      url: "/user-service/popup/pickUpCoupon",
      method: "post",
      data: inParameter(data),
  }).then((res)=>{
      res.data = decrypt(res.data)
      return res
  }).catch((err) => {});
}
